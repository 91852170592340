.ant-carousel .slick-slide {
  text-align: center;
  /* height: 400px; */
  /* height: 40vh; */
  /* height: 45vh; */
  /* line-height: 160px; */
  /* background: #364d79; */
  overflow: hidden;
  height: 340px;
}
@media only screen and (max-width: 1023px) {
  .ant-carousel .slick-slide {
    /* height: 38vh; */
    height: 205px;
  }
}
@media only screen and (max-width: 767px) {
  .ant-carousel .slick-slide {
    /* height: 30vh; */
    height: 154px;
  }
}
@media only screen and (max-width: 424px) {
  .ant-carousel .slick-slide {
    /* height: 25vh; */
    /* height: 22vh; */
    height: 85px;
  }
}
