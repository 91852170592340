.ol-overlaycontainer-stopevent {
  display: none
}

.ol-unselectable .ol-layers {
  display: block !important;
}

.map {
  width: 400px;
  height: 300px;
  overflow: hidden;
}

#marker {
  width: 20px;
  height: 20px;
  border: 1px solid #088;
  border-radius: 10px;
  background-color: #0FF;
  opacity: 0.5;
}