@media only screen and (max-width: 767px) {
  /* #map {
    display: none;
  } */

  /* #card-media {
    width: 55px;
    height: 55px;
    position: absolute;
    top: 0em;
    right: 8em;
  }

  #product-name {
    font-size: 1.25em;
  }

  #genetics-image {
    display: none;
  }

  #chemical-content {
    top: 0.75em !important;
    right: -1.5em !important;
  }

  #chemical-content-string {
    font-size: 1.25em;
  } */
}

@media only screen and (max-width: 1023px) {
  #map {
    width: 300px;
  }

  /* #secondary-info {
    display: none;
  }

  #genetics-image {
    top: 3em;
    right: 10.5em;
  }

  #chemical-content {
    top: 3em;
    right: 1em;
  } */

  #product-list {
    width: 100%;
  }

  #search-bar {
    width: 45%;
  }

  #sort-drop-down {
    width: 45%;
  }
}
